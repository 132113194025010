<template>
  <div class="fill-height">
    <NavigationBar />
    <v-main
      id="pages"
      class="fill-height"
    >
      <v-container
        fluid
        class="fill-height ma-0 pa-0"
      >
        <router-view />
      </v-container>
    </v-main>
    <v-overlay
      :opacity="1"
      :value="getLoading"
    >
      <v-progress-circular
        indeterminate
        size="84"
      >
        loading...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavigationBar from '@/layouts/components/NavigationBar'

export default {
  name: 'MainLayout',
  components: { NavigationBar },
  computed: {
    ...mapGetters({
      getLoading: 'Index/loading',
    }),
  },
}
</script>
