import { EventStore, EventStoreConfig } from '@bryntum/schedulerpro'
import SessionModel from '@/components/bryntum/models/SessionModel'

class SessionStore extends EventStore {
  static $name = 'SessionStore'

  static get defaultConfig(): Partial<EventStoreConfig> {
    return {
      modelClass: SessionModel,
      // It is true by default for Vue wrapper
      syncDataOnLoad: false,
    }
  }
}

export default SessionStore
