import { DateHelper } from '@bryntum/schedulerpro'

class BenchmarkDateHelper extends DateHelper {
  // "155 minutes" => "2h 35min"
  // "120 minutes" => "2h"
  static convertDurationToNiceTime(duration: number, unit: string, showZeroMinutes = false): string {
    const totalMinutes = BenchmarkDateHelper.as('minutes', duration, unit)
    const minutes = totalMinutes % 60
    const totalHours = Math.floor(totalMinutes / 60)
    const hours = totalHours % 24
    const totalDays = Math.floor(totalHours / 24)

    const time = []

    if (minutes > 0) {
      time.unshift(`${minutes}${BenchmarkDateHelper.getShortNameOfUnit('minute')}`)
    } else if (showZeroMinutes) {
      time.unshift(`00${BenchmarkDateHelper.getShortNameOfUnit('minute')}`)
    }

    if (hours > 0) {
      time.unshift(`${hours}${BenchmarkDateHelper.getShortNameOfUnit('hour')}`)
    }

    if (totalDays > 0) {
      time.unshift(`${totalDays}${BenchmarkDateHelper.getShortNameOfUnit('day')}`)
    }

    return time.length > 0 ? time.join(' ') : ''
  }

  static startOf(date: Date, unit = 'day', clone = true, weekStartDay = DateHelper.weekStartDay): Date {
    unit = this.normalizeUnit(unit)

    if (clone) {
      date = this.clone(date)
    }

    // Override start of week calculation due to the bug https://forum.bryntum.com/viewtopic.php?t=28476
    if (unit === 'week') {
      const weekBasedOnSunday = [0, 1, 2, 3, 4, 5, 6]
      // For example week based on Monday is [1, 2, 3, 4, 5, 6, 0]
      const weekBasedOnWeekStartDay = [
        ...weekBasedOnSunday.slice(weekStartDay),
        ...weekBasedOnSunday.slice(0, weekStartDay)
      ]
      const currentDay = date.getDay()
      const delta = weekBasedOnWeekStartDay.indexOf(currentDay)

      date.setDate(date.getDate() - delta)
      date.setHours(0, 0, 0, 0)
      return date
    }

    return super.startOf.call(this, date, unit, clone, weekStartDay)
  }
}

export default BenchmarkDateHelper
