import { ButtonConfig, ComboConfig } from '@bryntum/schedulerpro'
import { ZoomLevelsConfig } from '@/components/bryntum/configs/ZoomLevelsConfig'
import { AVAILABLE_THEMES } from '@/components/bryntum/configs/ThemeSwitcherConfig'

const ViewPresetComboConfig: Partial<ComboConfig> = {
  width: 90,
  editable: false,
  displayField: 'name',
  valueField: 'id',
  items: [...ZoomLevelsConfig],
}

const FitButtonConfig: Partial<ButtonConfig> = {
  text: 'Fit',
  icon: 'b-fa-arrows-alt-h',
  tooltip: 'Adjust how locations fit the browser width',
  menu: {
    items: {
      none: {
        text: 'No fit',
        tooltip: 'Column width is equal to configured value. See the Sizing tool.',
        checked: false,
        closeParent: true
      },
      fill: {
        text: 'Fill width',
        tooltip: 'Stretches columns to fill all available space. Has no effect if total column width greater than available space.',
        checked: false,
        closeParent: true
      },
      fit: {
        text: 'Fit width',
        tooltip: 'Makes columns to take all available space. Ignores column width.',
        checked: false,
        closeParent: true
      }
    },
  }
}

const LayoutButtonConfig: Partial<ButtonConfig> = {
  text: 'Layout',
  icon: 'b-fa-layer-group',
  tooltip: 'Adjust how sessions look when they overlap',
  menu: {
    items: {
      none: {
        text: 'Overlap',
        tooltip: 'Sessions take all column width',
        checked: false,
        closeParent: true
      },
      pack: {
        text: 'Pack',
        tooltip: 'Sessions share column width equally',
        checked: false,
        closeParent: true
      },
      mixed: {
        text: 'Mixed',
        tooltip: '2 sessions overlap, 3 and more sessions pack',
        checked: false,
        closeParent: true
      }
    }
  }
}

const SizingButtonConfig: Partial<ButtonConfig> = {
  text: 'Sizing',
  icon: 'b-fa-sliders-h',
  tooltip: 'Adjust how much space a location or the timeline takes',
  menu: {
    columnWidth: {
      type: 'slider',
      text: 'Column width',
      min: 60,
      max: 340,
      value: 60,
    },
    tickHeight: {
      type: 'slider',
      text: 'Tick height',
      style: 'margin-top: .6em',
      min: 20,
      max: 280,
      value: 20,
    },
  }
}

const ThemeComboConfig: Partial<ComboConfig> = {
  tooltip: 'Adjust color scheme of the calendar',
  editable: false,
  inputWidth: 70,
  pickerWidth: 130,
  listCls: 'theme-picker',
  displayField: 'name',
  displayValueRenderer: () => 'Theme',
  items: [...AVAILABLE_THEMES],
}

export {
  FitButtonConfig,
  LayoutButtonConfig,
  SizingButtonConfig,
  ThemeComboConfig,
  ViewPresetComboConfig,
}
